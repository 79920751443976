const tireFields = ['Width', 'Aspect', 'Rim'];

const tireRequestPanelConfig = {
  isAutoRedirectDisabled: true,
  selectFields: tireFields,
  onSubmit: closeTiresDialog,
  useNativeDropdown: true,
};

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  InitFunc: createDialog,
  SearchRequestDefaults: {
    pageSize: 48,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#convermax_search_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Relevancy',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
        'ReviewAverage:desc,ReviewCount:desc': 'Average Rating',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields: tireFields,
    },
    {
      name: 'TirePanel',
      type: 'RequestPanel',
      location: {
        firstChildOf: 'header .col-sm-6.hidden-xs',
        class: 'cm_req-panel_tires_container cm_mobile-hide',
      },
      template: 'RequestTirePanel',
      ...tireRequestPanelConfig,
    },
    {
      name: 'TirePanelMobile',
      type: 'RequestPanel',
      location: {
        insertAfter: '.header-fixed-top',
        class: 'cm_req-panel_tires_container cm_desktop-hide container',
      },
      template: 'RequestTirePanel',
      ...tireRequestPanelConfig,
    },
    {
      name: 'TirePanelDialog',
      type: 'RequestPanel',
      location: {
        firstChildOf: '#cm_dialog_req-panel_tires',
        class: 'cm_req-panel_tires_container',
      },
      template: 'RequestTirePanel',
      ...tireRequestPanelConfig,
    },
    {
      name: 'SearchBox',
      type: 'SearchBox',
      location: '#searchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialog',
      type: 'SearchBox',
      location: '#cm_dialog_search-box',
      template: 'SearchBox',
      disableDropdown: true,
      onSubmit: closeTiresDialog,
    },
    {
      name: 'SearchResult',
      instantInfiniteScroll: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
  ],
};

const requestPanelDialogSelector = '.cm_dialog.RequestPanelDialog';
function openTiresDialog() {
  document.body.classList.add('cm_no-scroll');
  document.documentElement.classList.add('cm_no-scroll');
  document.querySelector(requestPanelDialogSelector).classList.add('is-open');
}
function closeTiresDialog() {
  document.body.classList.remove('cm_no-scroll');
  document.documentElement.classList.remove('cm_no-scroll');
  document.querySelector(requestPanelDialogSelector).classList.remove('is-open');
}

function createDialog() {
  const dialogElement = document.createElement('div');
  dialogElement.innerHTML = `
      <div class="cm_dialog SearchBoxDialog RequestPanelDialog">
        <div class="cm_search-box-dialog_close-button close">
          <svg class='cm_icon cm_icon-times' height='20px' role='image' viewBox='0 0 22 22'>
            <path d='M1,1L21,21M21,1L1,21' />
          </svg>
        </div>
        <div class="dialog-container">
          <div class="dialog-content">
            <div class="dialog-header">
              <div class="dialog-title">Store Search</div>
            </div>
            <div id="cm_dialog_search-box"></div>
            <div class="dialog_split-bar"> - or - </div>
            <div class="cm_req-panel_tires_title dialog-title">Search by Size</div>
            <div id="cm_dialog_req-panel_tires"></div>
          </div>
        </div>
      </div>        
    `;

  document.body.appendChild(dialogElement.firstElementChild);

  document.querySelector(`${requestPanelDialogSelector} .close`).addEventListener('click', closeTiresDialog);

  const mobileSearchElements = document.querySelectorAll('[data-target="#searchModal"]');

  mobileSearchElements.forEach((el) => {
    const elClone = el.cloneNode(true);
    elClone.removeAttribute('data-toggle');
    el.parentNode.replaceChild(elClone, el);
    elClone.addEventListener('click', openTiresDialog);
  });
}
