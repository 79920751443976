//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-260:_5596,_2744,_4480,_784,_1136,_7496,_9096,_8408;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-260')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-260', "_5596,_2744,_4480,_784,_1136,_7496,_9096,_8408");
        }
      }catch (ex) {
        console.error(ex);
      }