import { setResponse } from 'Core/actions/response.ts';
import { assignLocation, pushToHistory, replaceInHistory } from 'Core/actions/redirect.ts';
import { scrollToBase } from 'Core/actions/show.ts';
import { skipRequest } from 'Core/actions/request.js';
import { uriChangeHandled } from 'Core/history.js';
import {
  createFitmentSearchRequestSelectionSelector,
  createIsServerChangedVehicleSelector,
  isPageLoadedWithVehicleInUriSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import {
  searchLastSearchOptionsSelector,
  searchRequestSelectionSelector,
  searchRequestSelector,
} from 'Core/selectors/search.js';
import { distinctSelection } from 'Core/reducers/search/request/selection.js';
import { FacetValue } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { getUriFromRequest, getRequestFromUri, getVehicleFromUri } from 'Modules/converter/index.js';
import { equalByMany } from 'Utils/array.js';
import defaultSearch from './defaultSearch.ts';
import tryAutocompleteRedirect, { shouldSearch } from './tryAutocompleteRedirect.js';
import sendRequestWithPreselection from './sendRequest.js';
import { infiniteScrollEnabled } from '!!extract-features!Stores/discounttirezone/config.js';

export default function search(arg) {
  const { state, action, dispatch } = arg;
  // TODO: remove when sendRequest will be fully moved to action.meta
  const sendRequest = action.sendRequest ?? action.meta?.sendRequest;

  if (state.search.retriesCount > 1) {
    return;
  }

  const result = tryAutocompleteRedirect(arg);
  result.actions.filter(Boolean).forEach(dispatch);
  if (
    result.shouldSearch === shouldSearch.forbid ||
    (result.shouldSearch === shouldSearch.allow && !sendRequest && action.type !== setResponse.type)
  ) {
    return;
  }
  // else search is forced

  const searchOptions = searchLastSearchOptionsSelector(state);
  const searchRequest = searchRequestSelector(state);
  const searchRequestSelection = searchRequestSelectionSelector(state);
  const fitmentSearchRequestSelection = createFitmentSearchRequestSelectionSelector()(state);

  const isVehicleInUriNew = getVehicleFromUri().isVehicleNew;
  const isPageLoadedWithVehicleInUri = isPageLoadedWithVehicleInUriSelector(state);

  const vehicleChangedByServer = createIsServerChangedVehicleSelector(null)(state);
  const showVehicleInUrl = !fitmentSearchConfig.hideVehicleFromUrl && !vehicleChangedByServer;

  const isVehicleSelectionShouldBeIncluded =
    (showVehicleInUrl || fitmentSearchConfig.doNotSaveSelectedVehicle) &&
    (requestConfig.hasSearchResults || isPageLoadedWithVehicleInUri) &&
    fitmentSearchConfig.isFitmentSearchWidgetsVisible &&
    !(action.onInit && isVehicleInUriNew);

  const combinedRequest = {
    ...searchRequest,
    selection: isVehicleSelectionShouldBeIncluded
      ? distinctSelection([...searchRequestSelection, ...fitmentSearchRequestSelection])
      : searchRequestSelection,
  };

  const newLocation = getUriFromRequest({ ...combinedRequest, defaultSearch }, searchOptions);

  if (action.type === setResponse.type) {
    if (
      requestDiffersFromUri(combinedRequest, window.location) &&
      // if not on the category selection page. redirects are handled in the FacetTiles component
      !fitmentSearchConfig.isOnCategorySelectionPage &&
      !isVehicleInUriNew
    ) {
      dispatch(replaceInHistory(newLocation, null));
    }
    return;
  }

  if (searchOptions.fromUri) {
    if (action.onInit && requestDiffersFromUri(combinedRequest, window.location)) {
      dispatch(pushToHistory(newLocation, uriChangeHandled));
    }

    if (infiniteScrollEnabled) {
      const firstRequestForInfinite = { ...searchRequest };

      firstRequestForInfinite.pageNumber = 0;
      firstRequestForInfinite.pageSize *= searchRequest.pageNumber + 1;

      sendRequestWithPreselection(arg, firstRequestForInfinite);
    } else {
      sendRequestWithPreselection(arg, searchRequest);
    }
    return;
  }

  if (window.location.pathname !== newLocation.pathname) {
    const selection = searchRequest.selection
      .concat(!searchOptions.ignoreLocalPreselection && requestConfig.localPreselection)
      .filter(Boolean);

    const { href } = getUriFromRequest({ ...searchRequest, selection }, searchOptions);
    dispatch(assignLocation(href));
  } else if (requestDiffersFromUri(combinedRequest, window.location)) {
    dispatch(pushToHistory(newLocation, uriChangeHandled));
    if (uiConfig.enableRequestScroll) {
      dispatch(scrollToBase());
    }
    sendRequestWithPreselection(arg, searchRequest);
  } else if (searchOptions.allowSearchSameAsUri) {
    sendRequestWithPreselection(arg, searchRequest);
  } else {
    dispatch(skipRequest());
  }
}

const compareKeys = [
  ['selection', FacetValue.termKey],
  'filterQuery',
  'sort',
  'catalog',
  'pageNumber',
  'pageSize',
  'query',
];
function requestDiffersFromUri(request, location) {
  const uriRequest = getRequestFromUri(location);
  return !equalByMany(request, uriRequest, ...compareKeys);
}
