export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'btn btn-primary',

  SEARCH_BOX_PLACEHOLDER: 'Keyword Search',
  SEARCH_BOX_BUTTON_CLASS: 'btn btn-default',

  SORT_BY: 'Sort products:&nbsp;',

  SELECT_CONTAINER_CLASS: 'cm_vehicle-widget_select',
};
